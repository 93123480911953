import { useQuery } from "./useQuery";
import { returnFetch } from "../helpers/returnFetch";

export const useCash = (props) => {
  const query = props?.query;

  const { loading, payload, refetch } = useQuery({
    endpoint: `cash/`,
    query,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "cash", body });
    return data;
  };

  return {
    loading,
    payload,
    refetch,
    save,
  };
};
