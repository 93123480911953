import { DATE_FORMAT } from "../../../constants";
import { useContainers } from "../../../OdevFetch";
import moment from "moment";

const Containers = () => {
  const { payload, loading } = useContainers();
  const containers = payload ? payload.data.results : "";

  if (loading) return <div>LOADING</div>;

  return (
    <div className="container-fluid">
      <h1 class="h3 mt-4 mb-3 text-gray-800">Lista kontenerów</h1>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <table
              className="table table-bordered"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Imię</th>
                  <th>Nazwisko</th>
                  <th>Telefon</th>
                  <th>Koniec rezerwacji</th>
                  {/* <th>Kwota</th> */}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {containers.map((item) => (
                  <tr className={!item?.reservation?.id ? "btn-success" : ""}>
                    <td>{item.description}</td>
                    <td>{item?.client?.name || "-"}</td>
                    <td>{item?.client?.lastName || "-"}</td>
                    <td>{item?.client?.phone || "-"}</td>
                    <td>
                      {(item?.reservation?.reservation_end &&
                        moment(item.reservation.reservation_end).format(
                          DATE_FORMAT
                        )) ||
                        "-"}
                    </td>
                    {/* <td>{item.cost}</td> */}
                    <td>{item?.reservation?.id ? "Zarezerwowany" : "Wolny"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Containers;
