import React from "react";

import "./Popup.scss";

const Popup = ({ onClose, children }) => {
  return (
    <div className="popup open">
      <div className="popup-content card">
        <button className="close-button" onClick={onClose}>
          x
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
