import styles from "./Banner.module.scss";
import { Container } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { FaRegClock, FaShieldAlt } from "react-icons/fa";
import { BsCalendarDateFill } from "react-icons/bs";

const cardContent = [
  {
    icon: <FaRegClock />,
    header: "Dostęp 24/7",
    description: <p></p>,
    color: "#f44236",
  },
  {
    icon: <FaShieldAlt />,
    header: "Monitoring i ochrona",
    description: <p></p>,
    color: "#f44236",
  },
  {
    icon: <BsCalendarDateFill />,
    header: "Elastyczny okres najmu",
    description: <p></p>,
    color: "#f44236",
  },
];

const Banner = () => {
  return (
    <section id="banner" className={styles.root}>
      <div className={styles.root_image}>
        <div className={styles.root_image_header}>
          <h1>Brakuje Ci miejsca?</h1>
          <h5>Przechowaj swoje rzeczy w bezpiecznym schowku</h5>
        </div>
      </div>
      <div className={styles.root_cards}>
        <Container>
          <div className={styles.root_cards_container}>
            {cardContent.map((content) => (
              <Card
                className={styles.card_body}
                key={content.header}
                style={{
                  width: "30%",
                  boxShadow: "0px 0px 25px -12px rgba(0, 0, 0, 1)",
                  color: "#000",
                }}
              >
                <Card.Body>
                  <Card.Title>
                    <h1 style={{ color: content.color }}>{content.icon}</h1>
                  </Card.Title>
                  <Card.Subtitle className="mb-2">
                    <h5 style={{ color: "#f44236" }}>{content.header}</h5>
                  </Card.Subtitle>
                  {/* {content.description} */}
                </Card.Body>
              </Card>
            ))}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Banner;
