import styles from "./About.module.scss";

const About = () => {
  return (
    <section id="about" className={styles.root}>
      <h4>Szukasz magazynu dla firmy lub na potrzeby prywatne?</h4>
      <h4>Chcesz przechować gdzieś rzeczy, na które nie masz miejsca?</h4>
      <h4>Bezpieczny schowek to idealne rozwiązanie dla Ciebie.</h4>
    </section>
  );
};

export default About;
