import { returnFetch } from "../helpers/returnFetch";
import { useQuery } from "./useQuery";

export const useReservations = (props) => {
  const query = props?.query;
  const isLazy = props?.isLazy;
  const { loading, payload, refetch } = useQuery({
    endpoint: "reservations",
    query,
    isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "reservation", body });
    return data;
  };

  const panelSave = async ({ body }) => {
    const data = await returnFetch({ endpoint: "panel/reservation/", body });
    return data;
  };

  const remove = async ({ id }) => {
    const data = await returnFetch({ endpoint: `reservation/${id}/remove` });
    return data;
  };

  const edit = async ({ body }) => {
    const data = await returnFetch({
      endpoint: `reservation/${body.id}`,
      body,
    });
    return data;
  };

  return {
    loading,
    payload,
    refetch,
    save,
    edit,
    remove,
    panelSave,
  };
};
