import styles from "./Rent.module.scss";
import { Container } from "react-bootstrap";
import { useFreeContainers } from "../../OdevFetch/hooks/useFreeContainers";
import { useState, useEffect } from "react";
import ContainerSingle from "../ContainerSingle/ContainerSingle";
import RegisterWidget from "../RegisterWidget";
import ContainerTileTable from "../ContainerTileTable/ContainerTileTable";
import moment from "moment";
import { DATE_FORMAT } from "../../constants";

const Rent = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const [fromDate, setFromDate] = useState(moment().format(DATE_FORMAT));
  const [duration, setDuration] = useState("3");

  const { payload, loading, refetch } = useFreeContainers({
    query: { fromDate, duration },
  });

  const [selectedContainer, setSelectedContainer] = useState(null);
  console.log(selectedContainer);

  const containerData = payload?.data ?? [];
  console.log(containerData);

  const submitHandler = (e) => {
    e.preventDefault();
    refetch({ query: { fromDate, duration } });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading) return <div>Ładowanie</div>;

  if (selectedContainer?.id)
    return (
      <section id="rent selected-container" className={styles.root}>
        <Container>
          <ContainerSingle
            containerData={selectedContainer}
            fromDate={fromDate}
            duration={duration}
            onBack={() => setSelectedContainer(null)}
          />
        </Container>
      </section>
    );

  const filterComponent = (
    <div className={styles.root_searchBar}>
      <h3>Zarezerwuj kontener on-line</h3>
      <h6>
        Jeśli chcesz zarezerwować kontener na czas krótszy, niż 3 miesiące,
        skontaktuj się telefonicznie lub mailowo.
      </h6>
      <div className={styles.root_searchBar_calendar}>
        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        ></input>
        <select value={duration} onChange={(e) => setDuration(e.target.value)}>
          {[3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => (
            <option value={num}>{num} miesięcy</option>
          ))}
        </select>
        <button onClick={submitHandler}>Filtruj</button>
      </div>
    </div>
  );

  if (windowWidth <= 767) {
    return (
      <section id="rent" className={styles.root}>
        <Container>
          {filterComponent}
          <div className={styles.root_containersTable}>
            {containerData.map((data) => (
              <ContainerTileTable
                data={data}
                key={data.id}
                setSelectedContainer={() => setSelectedContainer(data)}
              />
            ))}
          </div>
        </Container>
      </section>
    );
  }

  return (
    <section id="rent" className={styles.root}>
      {filterComponent}
      <div className={styles.root_containers}>
        <RegisterWidget
          containers={containerData}
          setSelectedContainer={setSelectedContainer}
        />
      </div>
    </section>
  );
};

export default Rent;
