import { returnFetch } from "../helpers/returnFetch";

export const useSignIn = () => {
  const signIn = async ({ body }) => {
    const data = await returnFetch({ endpoint: "sign-in", body });
    return data;
  };

  return {
    signIn,
  };
};
