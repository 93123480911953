import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useClients } from "../../../../OdevFetch";

const MyForm = ({ onClose, clientData, action, refetch }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { save, remove } = useClients();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const initialValues = clientData
    ? clientData
    : {
        name: "",
        lastName: "",
        phone: "",
        mail: "",
      };

  const onSubmit = async (body) => {
    await save({body});
    onClose();
    refetch();
  };

  const onRemove = async () => {
    await remove({id: clientData.id});
    onClose();
    refetch();
  };

  if (action === "remove") {
    return (
      <div className="modal fade show" onClick={closeModal}>
        <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5>
                Czy na pewno chcesz usunąć klienta{" "}
                <strong>{clientData.name}</strong>?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-danger" onClick={() => onRemove()}>
                Usuń
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={onClose}
              >
                Zamknij
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="modal fade show" onClick={closeModal}>
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myFormModalLabel">
                  Dane klienta
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                ></button>
              </div>
              <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <Form>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Imię:
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">
                        Nazwisko:
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label">
                        Numer telefonu:
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="mail" className="form-label">
                        Email:
                      </label>
                      <Field
                        type="mail"
                        className="form-control"
                        id="mail"
                        name="mail"
                      />
                      <ErrorMessage
                        name="mail"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Wyślij
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={onClose}
                    >
                      Zamknij
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default MyForm;
