import NaviMain from "../../modules/NaviMain/NaviMain";
import Banner from "../../modules/Banner/Banner";
import About from "../../modules/About/About";
import Offert from "../../modules/Offert/Offert";
import Rent from "../../modules/Rent/Rent";
import Contact from "../../modules/Contact/Contact";
import Faq from "../../modules/FAQ/faq";
import Footer from "../../modules/Footer/Footer";

const MainPage = () => {
  return (
    <div id="home">
      <NaviMain />
      <Banner />
      <About />
      <Offert />
      <Rent />
      <Faq />
      <Contact />
      <Footer />
    </div>
  );
};

export default MainPage;
