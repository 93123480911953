import { IMAGES } from "../../RegisterWidget/config";
import "./styles.scss";

const imagesHandler = (container) => {
  console.log(container);
  switch (container.type) {
    case "1":
      return IMAGES.TYPE_1_FRONT;
    case "2":
      return IMAGES.TYPE_1_FRONT;
    case "3":
      return IMAGES.TYPE_3;
    case "4":
      return IMAGES.TYPE_4;
    case "5":
      return IMAGES.TYPE_5;
    default:
      return container.size;
  }
};

const containerConfig = {
  1: {
    size: "6m²",
    width: "2,2 m",
    height: "2,5 m",
    depht: "6 m",
    cost: "450zł netto / miesiąc",
    doorSize: "200cm",
  },
  2: {
    size: "2m²",
    width: "1,1 m",
    height: "2,5 m",
    depht: "2,2 m",
    cost: "175zł netto / miesiąc",
    doorSize: "100cm",
  },
  3: {
    size: "2m²",
    width: "1,8 m",
    height: "2,5 m",
    depht: "2,2 m",
    cost: "250zł netto / miesiąc",
    doorSize: "100cm",
  },
  4: {
    size: "50m²",
    width: "4 m",
    height: "2,5 m",
    depht: "12 m",
    cost: "1500zł netto / miesiąc",
    doorSize: "200cm",
  },
  5: {
    size: "12m²",
    width: "2,2 m",
    height: "2,5 m",
    depht: "12 m",
    cost: "900zł netto / miesiąc",
    doorSize: "200cm",
  },
};

const ContainerPresentation = ({ params }) => {
  const config = containerConfig[params.type];

  return (
    <div className="container-presentation-module">
      <h2>
        Rezerwujesz kontener nr <b>{params.description}</b>
      </h2>
      <div className="row">
        <div className="col-6 d-none d-sm-block">
          <img src={imagesHandler(params)} alt="container" />
        </div>
        <div className="col-12 col-sm-6 params-table">
          <table>
            <tbody>
              <tr>
                <td>
                  Szerokość: <b>{config.width}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Wysokość: <b>{config.height}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Głębokość: <b>{config.depht}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Cena netto: <b>{params.singlePrice} zł / miesiąc</b>
                </td>
              </tr>
              <tr>
                <td>
                  Cena brutto:{" "}
                  <b>
                    {(parseFloat(params.singlePrice) * 1.23).toFixed(2)} zł /
                    miesiąc
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Wymiary drzwi: <b>{config.doorSize}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <p>
          Po prawej stronie znajduje się formularz rezerwacyjny, który musisz
          wypełnić. Jeśli chcesz otrzymać fakturę, kliknij przycisk "Faktura" i
          wprowadź swoje dane. Jeśli chcesz wybrać inny kontener, kliknij
          przycisk "Wróć". Przeniesie Cię to z powrotem do strony wyboru
          kontenerów. Jeśli jesteś zdecydowany, kliknij przycisk "Zarezerwuj".
          Po złożeniu rezerwacji otrzymasz potwierdzenie na podany adres e-mail
          wraz z informacjami dotyczącymi płatności i kolejnymi krokami.
        </p>
      </div>
    </div>
  );
};

export default ContainerPresentation;
