import { Container } from "react-bootstrap";
import styles from "./Footer.module.scss";

import { BsTelephone } from "react-icons/bs";
import { RiMailSendLine } from "react-icons/ri";
import { AiFillFacebook } from "react-icons/ai";

const Footer = () => {
  return (
    <section id="footer" className={styles.root}>
      <Container>
        <div className={styles.root_footer}>
          {/** ADRES */}
          <div className={styles.root_box}>
            <p>Adres</p>
            <ul>
              <li>ul. Grzybowska 25</li>
              <li>78-100 Kołobrzeg</li>
            </ul>
          </div>
          {/** CONTACT */}
          <div className={styles.root_box}>
            <p>Kontakt</p>
            <ul>
              <li>
                <a href="tel:+48 543 243 434">
                  <BsTelephone />
                  543 243 434
                </a>
              </li>
              <li>
                <a href="mailto:krychowiak777@gmail.com">
                  <RiMailSendLine />
                  krychowiak777@gmail.com
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/MagazynyNaWynajemKol"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiFillFacebook /> Facebook
                </a>
              </li>
            </ul>
          </div>
          {/** INFO */}
          <div className={styles.root_box}>
            <p>Info</p>
            <ul>
              <li>
                <a href="/Regulamin_najmu_i_polityka_prywatnosci.pdf">
                  Regulamin najmu oraz polityka prywatności
                </a>
              </li>
              <li>
                <a href="#oferta">Oferta</a>
              </li>
              <li>
                <a href="#faq">FAQ</a>
              </li>
            </ul>
          </div>
          {/** MENU */}
        </div>
      </Container>
      <Container>
        <a
          className={styles.root_poweredLink}
          target="_blank"
          href="https://www.1v0.pl/"
        >
          Powered by 1v0
        </a>
      </Container>
    </section>
  );
};

export default Footer;
