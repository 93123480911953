import styles from './Contact.module.scss';
import ContactForm from '../ContactForm/ContactForm';

const Contact = () => {
  return (
    <section id='contact' className={styles.root}>
      <div className={styles.root_map}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2335.9978012096717!2d15.515854777069702!3d54.16240261335192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4700151dec390b4d%3A0x6103c7e79153bd6e!2sGrzybowska%2025%2C%2078-100%20Ko%C5%82obrzeg!5e0!3m2!1spl!2spl!4v1684160374958!5m2!1spl!2spl"  style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='map'>
        </iframe>
      </div>
      <div className={styles.root_contact}>
        <ContactForm/>
      </div>
    </section>
  )
};

export default Contact;