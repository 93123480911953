import { fetchSetting } from "../../OdevFetch/helpers/fetchConfig";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../vendor/sb-admin-2.min.scss";
import { useSignIn } from "../../OdevFetch/hooks/useSignin";

const Login = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { signIn } = useSignIn();

  useEffect(() => {
    if (sessionStorage.at !== undefined) {
      navigate("/panel");
    }
  });

  const loginHandler = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await signIn({
        body: {
          login,
          password,
        },
      });

      const {
        status,
        body: { accessToken },
      } = response;

      if (status === "success") {
        sessionStorage.clear();
        sessionStorage.setItem("access-token", accessToken);
        navigate("/panel");
      } else {
        setError("Błędne dane logowania");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="dashboard">
      <div className="bg-gradient-primary dashboard-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="row">
                          <div className="col-12 col-lg-8 text-center">
                            <h1 className="h4 text-gray-900 mb-4">
                              Witaj w panelu administracyjnym!
                            </h1>
                          </div>
                          <div className="col-12 col-lg-4 d-none d-lg-block">
                            <img
                              style={{ width: "100%", marginTop: "-10px" }}
                              src="/images/logo.png"
                            />
                          </div>
                        </div>

                        <form
                          onSubmit={(e) => loginHandler(e)}
                          className="user"
                        >
                          <div className="form-group">
                            <input
                              id="login"
                              className="form-control form-control-user"
                              placeholder="Login"
                              type="text"
                              value={login}
                              onChange={(e) => setLogin(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              id="password"
                              className="form-control form-control-user"
                              type="password"
                              placeholder="Hasło"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <button className="btn btn-danger btn-user btn-block">
                            Zaloguj
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
