import { useQuery } from "./useQuery";
import { returnFetch } from "../helpers/returnFetch";

export const useClients = (props) => {
  const query = props?.query;

  const { loading, payload, refetch } = useQuery({
    endpoint: `clients/`,
    query,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "clients", body });
    return data;
  };

  const remove = async({id}) => {
    const data = await returnFetch({endpoint: `clients/${id}/remove`});
    return data;
  }

  return {
    loading,
    payload,
    refetch,
    save,
    remove
  };
};
