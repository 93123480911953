import styles from "./ContactForm.module.scss";
import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./ContactForm.scss";
import { useMessage } from "../../OdevFetch";

const validateEmail = (value) => {
  let error;
  if (!value) {
    error = "Pole email jest wymagane";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    error = "Nieprawidłowy adres email";
  }
  return error;
};

const validateText = (value) => {
  let error;
  if (!value) {
    error = "To pole jest wymagane";
  }
  return error;
};

const validatePhoneNumber = (value) => {
  let error;
  if (!value) {
    error = "Pole numer telefonu jest wymagane";
  } else if (!/^[0-9]{9}$/i.test(value)) {
    error = "Nieprawidłowy numer telefonu";
  }
  return error;
};

const ContactForm = () => {
  const { send } = useMessage();

  const [isTimeExpired, setIsTimeExpired] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsTimeExpired(true);
      console.log("poszło");
    }, 10000); // Limit czasu: 60 sekund

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    if (isTimeExpired) {
      await send({ body: values });
      resetForm();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.root_card}>
        <div className={styles.root_card_header}>
          <h5>Napisz do nas!</h5>
          <p>
            Skontaktuj się z nami, jeśli masz pytania, sugestie lub potrzebujesz
            wsparcia technicznego - jesteśmy dostępni, aby Ci pomóc!
          </p>
        </div>
        <div className={styles.root_card_content}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              <div>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Imię i Nazwisko"
                  validate={validateText}
                />
                <ErrorMessage
                  className="error-message"
                  name="name"
                  component="div"
                />
              </div>

              <div>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  validate={validateEmail}
                />
                <ErrorMessage
                  className="error-message"
                  name="email"
                  component="div"
                />
              </div>

              <div>
                <Field
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Numer telefonu"
                  validate={validatePhoneNumber}
                />
                <ErrorMessage
                  className="error-message"
                  name="phoneNumber"
                  component="div"
                />
              </div>

              <div>
                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  placeholder="Wiadomość"
                  validate={validateText}
                />
                <ErrorMessage
                  className="error-message"
                  name="message"
                  component="div"
                />
              </div>

              <button type="submit">Wyślij</button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
