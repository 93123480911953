import styles from "../ContainerSingle.module.scss";

const Confirmation = ({ containerData }) => {
  return (
    <div className={styles.root_container_send}>
      <h4>Rezerwacja przebiegła pomyślnie!</h4>
      <p>
        Dziękujemy! Kontener numer <b>{containerData.description}</b> został
        zarezerwowany tymczasowo do momentu przelewu.
      </p>
      <p>Przelanie pieniędzy oznacza akceptację regulaminu.</p>
      <br />

      <p>Dane do przelewu zostaną przesłane na podany adres e-mail.</p>
      <p>
        Po zaksięgowaniu wpłaty otwrzymasz klucz/kod do wybranego kontenera.
      </p>
    </div>
  );
};

export default Confirmation;
