import Container from "react-bootstrap/Container";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";

import styles from "./NaviMain.module.scss";
import "./NaviMain.scss";

const liItems = [
  {
    key: 1,
    href: "#home",
    name: "Strona główna",
    isActive: true,
  },
  {
    key: 2,
    href: "#about",
    name: "O firmie",
    isActive: false,
  },
  {
    key: 3,
    href: "#offert",
    name: "Oferta",
    isActive: false,
  },
  {
    key: 4,
    href: "#faq",
    name: "FAQ",
    isActive: false,
  },
  {
    key: 5,
    href: "#contact",
    name: "Kontakt",
    isActive: false,
  },
  {
    key: 6,
    href: "#rent",
    name: "Zarezerwuj",
    isActive: false,
  },
];

const NaviMain = () => {
  const [hover, setHover] = useState(false);

  const clickHandler = (e) => {
    const navItems = document.getElementById("navlist").querySelectorAll("a");
    const target = e.target;

    for (let item of navItems) {
      if (item.className === "active") {
        item.classList.remove("active");
      }
    }

    target.classList.add("active");
  };

  return (
    <Container>
      <nav className={styles.root}>
        <div className={styles.root_logo}>
          <img src="/images/logo.png" alt="logo"></img>
        </div>
        <div className={styles.root_items}>
          <ul id="navlist">
            {liItems.map((item) => (
              <li key={item.key} onClick={clickHandler}>
                <a href={item.href} className={item.isActive ? "active" : ""}>
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div
          className={styles.root_mobileNavi}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <h1>
            <GiHamburgerMenu />
          </h1>
          <div
            className={
              hover
                ? styles.root_mobileNavi_items
                : styles.root_mobileNavi_itemsNone
            }
          >
            <ul id="navlist">
              {liItems.map((item) => (
                <li key={item.key}>
                  <a href={item.href}>{item.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </Container>
  );
};

export default NaviMain;
