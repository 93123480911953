import styles from "./Offert.module.scss";
import { Container } from "react-bootstrap";
import "./Offert.scss";

const Offert = () => {
  return (
    <div className="offert-module">
      <div className="gallery">
        <img src="images/gallery/gallery1.jpeg" alt="Obrazek 1" />
        <img src="images/gallery/gallery2.jpeg" alt="Obrazek 2" />
        <img src="images/gallery/gallery3.jpeg" alt="Obrazek 3" />
        <img src="images/gallery/gallery4.jpeg" alt="Obrazek 4" />
        <img src="images/gallery/gallery5.jpeg" alt="Obrazek 5" />
        <img src="images/gallery/gallery6.jpeg" alt="Obrazek 6" />
      </div>
      <Container>
        <section id="offert" className={styles.root}>
          <div className={styles.root_content}>
            <div className="description">
              <p>
                Oferujemy Państwu magazyny samoobsługowe do wynajęcia w
                Kołobrzegu
              </p>
              <p>
                Dostępne powierzchnie:
                <br />
                <br />
                <ul>
                  <li>
                    110 cm szerokości x 220 cm głębokości x 250 cm wysokości -
                    drzwi 100cm
                  </li>
                  <br />
                  <li>
                    170 cm szerokości x 220 cm głębokości x 250 cm wysokości -
                    drzwi 100cm
                  </li>

                  <br />
                  <li>
                    220 cm szerokości x 600 cm głębokości x 250 cm wysokości -
                    drzwi 200cm
                  </li>
                </ul>
              </p>
            </div>

            <p>
              Nasz park kontenerowy oferuje wysokie standardy w zakresie
              wynajmu. Kontenery to idealne rozwiązanie jako magazyn lub
              dodatkowa przestrzeń do przechowywania rzeczy. Jest to idealne
              rozwiązanie na czas przeprowadzki czy remontu.
              <br />
              <br />
              Oferta naszej firmy jest skierowana zarówno do firm jak i do osób
              prywatnych . Dysponujemy różnymi typami powierzchni. Idealne
              rozwiązanie na czas przeprowadzki czy remontu. Konstrukcja i
              szczelność kontenerów zapewnia bezpieczeństwo przechowywanych
              rzeczy w każdych warunkach atmosferycznych.
              <br />
              <br />
              Możliwość bezpośredniego podjazdu autem pod drzwi magazynu.
              Magazyny znajdują się na monitorowanym, zabezpieczonym placu i są
              dostępne 24/7
            </p>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default Offert;
