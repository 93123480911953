import { returnFetch } from "../helpers/returnFetch";

export const useMessage = () => {
  const send = async ({ body }) => {
    const data = await returnFetch({ endpoint: "form-message", body });
    return data;
  };

  return {
    send,
  };
};
