import { useReservations } from "../../../OdevFetch/hooks/useReservations";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants";
import { useState } from "react";
import Popup from "../Modules/Popup/Popup";

const Waiting = () => {
  const { payload, loading, edit, refetch, remove } = useReservations({
    query: { is_accepted: 1 },
  });

  const [invoicePopupState, setInvoicePopupState] = useState({
    data: {},
    isOpen: false,
  });

  const reservations = payload ? payload.data.results : "";

  const onRefetchHandler = () => {
    refetch({ query: { is_accepted: 1 } });
  };

  if (loading) return <div>LOADING</div>;

  return (
    <div className="container-fluid">
      <h1 class="h3 mt-4 mb-3 text-gray-800">Oczekujące na akceptację</h1>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <table
              className="table table-bordered"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>Id kontenera</th>
                  <th>Użytkownik</th>
                  <th>Numer telefonu</th>
                  <th>Data rozpoczęcia</th>
                  <th>Data zakończenia</th>
                  <th>Okres rezerwacji</th>
                  <th>Cena</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {reservations.map((item) => {
                  const { name, lastName, phone } = item.client;
                  const { reservation_start, reservation_end, price } = item;

                  const period = Math.ceil(
                    moment(reservation_end).diff(
                      reservation_start,
                      "months",
                      true
                    )
                  );

                  return (
                    <tr>
                      <td>{item.container.description}</td>
                      <td>{`${name} ${lastName}`}</td>
                      <td>{phone || "-"}</td>
                      <td>
                        {moment(reservation_start).format(DATE_FORMAT) || "-"}
                      </td>
                      <td>
                        {moment(reservation_end).format(DATE_FORMAT) || "-"}
                      </td>
                      <td>{period} miesięce/y</td>
                      <td>
                        {price} <br />/{(parseFloat(price) * 1.23).toFixed(2)}{" "}
                        zł
                      </td>
                      <td>
                        <button
                          onClick={async () => {
                            await edit({
                              body: { id: item.id, is_accepted: 2 },
                            });
                            onRefetchHandler();
                          }}
                          className="btn btn-success"
                        >
                          zaakceptuj
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={async () => {
                            await remove({ id: item.id });
                            onRefetchHandler();
                          }}
                          className="btn btn-danger"
                        >
                          odrzuć
                        </button>
                      </td>
                      <td>
                        {item.invoice_id && (
                          <button
                            onClick={() =>
                              setInvoicePopupState({
                                isOpen: true,
                                data: item.invoice,
                              })
                            }
                            className="btn btn-primary"
                          >
                            Dane do faktury
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {invoicePopupState.isOpen && (
        <Popup
          isOpen={true}
          onClose={() =>
            setInvoicePopupState({
              isOpen: false,
              data: null,
            })
          }
        >
          <h4>Dane fakturowe</h4>
          <ul>
            {[
              { name: "nip", label: "Nip" },
              { name: "company", label: "Nazwa Firmy" },
              { name: "name", label: "Imię" },
              { name: "lastName", label: "Nazwisko" },
              { name: "street", label: "Ulica" },
              { name: "buildingNumber", label: "Numer budynku" },
              { name: "localNumber", label: "Numer lokalu" },
              { name: "zipCode", label: "Kod pocztowy" },
            ].map((item) => (
              <li>
                <b>{item.label}: </b>
                <span>{invoicePopupState.data[item.name]}</span>
              </li>
            ))}
          </ul>
        </Popup>
      )}
    </div>
  );
};

export default Waiting;
