import { useState } from "react";
import styles from "../ContainerSingle.module.scss";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import "./styles.scss";

const InvoicesForm = ({ isVisible, invoiceData, onClose, onSave }) => {
  const formConfig = [
    { name: "name", label: "Imię" },
    { name: "lastName", label: "Nazwisko" },
    { name: "nip", label: "NIP" },
    { name: "company", label: "Firma" },
    { name: "city", label: "Miasto" },
    { name: "localNumber", label: "Numer lokalu" },
    { name: "buildingNumber", label: "Numer budynku" },
    { name: "street", label: "Ulica" },
    { name: "zipCode", label: "Kod pocztowy" },
  ];

  const getInitialState = () => {
    let initialState = {};

    if (invoiceData?.nip) {
      initialState = invoiceData;
    } else {
      formConfig.forEach((config) => {
        initialState[config.name] = "";
      });
    }

    return initialState;
  };

  const [inputsState, setInputState] = useState(getInitialState());

  const inputSetHandler = ({ id, value }) => {
    setInputState({
      ...inputsState,
      [id]: value,
    });
  };

  return (
    <>
      {invoiceData?.nip && (
        <div style={{ textAlign: "center" }}>
          <p>DANE DO FAKTURY</p>
          {inputsState.company && <div>{inputsState.company}</div>}
          {inputsState.nip && (
            <div>
              <b>{inputsState.nip}</b>
            </div>
          )}
          {(inputsState.name || inputsState.lastName) && (
            <div>{`${inputsState.name} ${inputsState.lastName}`}</div>
          )}
          <div>
            {inputsState.city && inputsState.city}
            {inputsState.street &&
              ` ul. ${inputsState.street} ${inputsState.buildingNumber}/${inputsState.localNumber}`}
          </div>
          {inputsState.zipCode && inputsState.zipCode}
        </div>
      )}
      <Modal
        className="invoices-form-module"
        show={isVisible}
        onHide={onClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Dane do faktury</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.root_modal}>
            <form>
              <div className="inputs-container">
                {formConfig.map((field) => {
                  return (
                    <div className="input-container">
                      <label htmlFor={field.name}>{field.label}</label>
                      <input
                        type="text"
                        placeholder={field.label}
                        value={inputsState[field.name]}
                        onChange={(e) =>
                          inputSetHandler({
                            id: field.name,
                            value: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  );
                })}
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              onClose();
              onSave(inputsState);
            }}
          >
            Zapisz
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setInputState(getInitialState());
              onClose();
            }}
          >
            Wróć
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InvoicesForm;
