import { useCash } from "../../../OdevFetch";
import { useState } from "react";

const containerDescription = {
  1: "6M",
  2: "2M - 110",
  3: "2M - 180",
  4: "50M",
  5: "12M",
};

const EditForm = ({ currentPrice, save }) => {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div>
      {!isEdit ? (
        <>
          {currentPrice}
          <button
            className="btn btn-warning ml-5"
            onClick={() => setIsEdit(true)}
          >
            Edytuj
          </button>
        </>
      ) : (
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const newPrice = e.target[0].value;
              save(newPrice);
              setIsEdit(false);
            }}
          >
            <input type="text" defaultValue={currentPrice} />
            <button type="submit" className="btn btn-primary">
              Zapisz
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

const Cash = () => {
  const { payload, loading, refetch, save } = useCash();
  const updatePrice = async ({ newPrice, id }) => {
    const parsedPrice = newPrice.replace(",", ".");
    await save({ body: { id, price: newPrice } });
    await refetch();
  };
  const clients = payload ? payload.data.results : [];

  if (loading) return <div>LOADING</div>;

  return (
    <div className="container-fluid">
      <h1 class="h3 mt-4 mb-3 text-gray-800">Ceny</h1>
      <div className="card shadow mb-4"></div>
      <div className="card-body">
        <div className="table-responsive">
          <table
            className="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Typ kontenerów</th>
                <th>Cena</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((item) => (
                <tr>
                  <td>{item.id}</td>
                  <td>{containerDescription[item.container_type]}</td>
                  <td>
                    <EditForm
                      id={item.id}
                      currentPrice={item.price}
                      save={(newPrice) =>
                        updatePrice({ id: item.id, newPrice })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Cash;
