import styles from "./faq.module.scss";
import ContactForm from "../ContactForm/ContactForm";
import { Accordion, Container } from "react-bootstrap";

const questionsCol1 = [
  {
    header: "Czy zapewniają Państwo transport rzeczy z/do magazynu?",
    description: `Nie posiadamy własnej firmy transportowej, natomiast chętnie doradzimy jak zorganizować
      transport i przekażemy kontakt do współpracujących z nami firm transportowych.`,
  },
  {
    header: "Czy możemy zlecić pakowanie przesyłek?",
    description: `Nasz personel nie ma dostępu do pomieszczenia Klienta a co za tym idzie do towaru i rzeczy,
      w związku z tym nie mamy również możliwości pakowania przesyłek. W razie takiej potrzeby
      Klienci często zatrudniają osobę, która zajmuje się przesyłkami w pomieszczeniu,
      przygotowuje je do wysyłki i następnie powierza pod naszą opiekę a my wydajemy ją
      zamówionemu wcześniej przez Klienta kurierowi.
      `,
  },
  {
    header: "Czy w pomieszczeniu znajdują się półki?",
    description: `Oferowane przez nas pomieszczenia są niezagospodarowane z uwagi na zróżnicowane
    zapotrzebowanie Klientów i nie można ich modyfikować. Najczęstsze rozwiązanie Klientów
    to regały magazynowe.`,
  },
  {
    header: "Jak mogę zakończyć wynajem pomieszczenia?",
    description: `W celu zakończenia umowy prosimy o przesłanie pisemnego wypowiedzenia w formie
    elektronicznej (mailem) bądź pocztą.`,
  },
  {
    header: "W jaki sposób zabezpieczone są pomieszczenia?",
    description: `Teren wyposażony jest w 24-godzinny monitoring, każdy kontener otrzymuje swój osobisty
    kod dostępu. Kod umożliwia wejście do magazynu 24 godziny na dobę. Każde pomieszczenie
    jest dodatkowo zabezpieczone własną kłódką.`,
  },
  {
    header: "Czy są jakieś rzeczy, których nie można przechowywać?",
    description: `Ze względów bezpieczeństwa w naszych magazynach nie mogą być złożone następujące
    rzeczy:
    rośliny, zwierzęta
    których przechowywanie, w tym w zakresie szczególnych wymogów lub zasad
    przechowywania, regulują odrębne przepisy prawa;
    materiały wybuchowe, materiały łatwopalne, substancje toksyczne, środki żrące
    produkty łatwo psujące się lub narażone na zepsucie;
    Odpowiadając również na częste pytania Panów – nie można przechować u nas teściowej,
    psa i dzieci?`,
  },
  {
    header: "Czy mogę przechować motocykl, łódkę, skuter itp.?",
    description: `Tak, w naszych magazynach można przechować motocykl, łódkę, skuter, samochód itp. –
    należy wcześniej skontaktować się z personelem, by sprawdzić dostępność pomieszczeń na
    tego typu rzeczy. Konieczne jest również przechowywanie ich bez paliwa zatem za każdym
    razem wstawiając je do pomieszczenia należy opróżniać zbiornik paliwowy do minimum.`,
  },
];

const questionsCol2 = [
  {
    header: "Czy pomieszczenie mogę wynająć od zaraz?",
    description: `Tak. Pomieszczenie można wynająć od zaraz. Formalności to tylko kilka minut, konieczna jest
    również opłata z góry – po czym możesz korzystać ze swojego wynajętego pomieszczenia.`,
  },
  {
    header: "Jaki jest minimalny okres wynajmu?",
    description: `Umowę możesz podpisać na godzinę. Oferujemy elastyczne formy wynajmu. Najlepiej
    skontaktować się bezpośrednio i dogadać szczegóły.`,
  },
  {
    header: "Jakie warunki należy spełniać żeby podpisać umowę?",
    description: `Umowę można podpisać na osobę prywatną i firmę
    W celu zawarcia umowy przez osobę prywatną prosimy o okazanie ważnego dokumentu
    tożsamości (dowód osobisty, paszport).
    W przypadku firm wymagany jest numer NIP oraz pełnomocnictwo do podpisywania umów.
    W wyjątkowych sytuacjach pobierana jest kaucja gwarancyjna w wysokości
    dwumiesięcznego czynszu i zwracana po zakończeniu umowy.
    Korzystamy również z Krajowego Rejestru Dłużników, w którym możemy sprawdzić status
    Klienta w celu finalizacji umowy.`,
  },
  {
    header: "Ile kosztuje wynajęcie pomieszczenia self storage?",
    description: `Cena zależy od wielkości pomieszczenia, lokalizacji i długości umowy. Zazwyczaj potrzebne
    jest mniejsze pomieszczenie niż nam się wydaje.
    `,
  },
  {
    header:
      "Czy istnieje możliwość obejrzenia pomieszczenia przed jego wynajęciem?",
    description: `Jak najbardziej. Zapraszamy do naszych oddziałów. Nasi pracownicy są do Twojej dyspozycji
    od poniedziałku do piątku od 09:00 do 17:00.
    `,
  },
  {
    header: "Czy magazyny są szczelne i nie występuje problem z wilgocią?",
    description: `Tak magazyny są szczelne, zbudowane są z kontenerów morskich, przeznaczonych fabrycznie
    do transportu w każdych warunkach. Wszystkie magazyny są fabrycznie wentylowane,
    wilgoć nie występuje.
    `,
  },
];

const Faq = () => {
  return (
    <section id="faq">
      <div className={styles.root}>
        <div className={styles.root_wrapper}>
          <h1>FAQ</h1>
        </div>

        <Container>
          <div className={styles.root_header}>
            <h3>Najczęściej zadawane pytania</h3>
            <p>
              Często Zadawane Pytania (FAQ) to sekcja, w której zebraliśmy
              odpowiedzi na najbardziej powszechne pytania, które otrzymujemy od
              naszych użytkowników. Jeśli masz wątpliwości lub potrzebujesz
              dodatkowych informacji, skorzystaj z formularza kontaktowego
              poniżej.
            </p>
          </div>
          <div className={styles.root_content}>
            <div className={styles.root_content_wraps}>
              {questionsCol1.map((question) => (
                <Accordion
                  key={question.header}
                  style={{ paddingBottom: "1rem" }}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className={styles.root_accordion_button}>
                      {question.header}
                    </Accordion.Header>
                    <Accordion.Body>{question.description}</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </div>
            <div className={styles.root_content_wraps}>
              {questionsCol2.map((question) => (
                <Accordion
                  key={question.header}
                  style={{ paddingBottom: "1rem" }}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className={styles.root_accordion_button}>
                      {question.header}
                    </Accordion.Header>
                    <Accordion.Body>{question.description}</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Faq;
