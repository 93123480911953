import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useContainers } from "../../../../OdevFetch";
import { useReservations } from "../../../../OdevFetch/hooks/useReservations";
import moment from "moment";
import { DATE_FORMAT } from "../../../../constants";

const ReservationForm = ({ onClose, reservationData, refetch }) => {
  const { payload, loading } = useContainers();
  const { panelSave } = useReservations();

  if (loading) return <div>LOADING...</div>;

  const initialValues = {
    id: reservationData?.id || undefined,
    name: reservationData?.client?.name || "",
    lastName: reservationData?.client?.lastName || "",
    phone: reservationData?.client?.phone || "",
    mail: reservationData?.client?.mail || "",
    reservation_start:
      (reservationData &&
        moment(reservationData?.reservation_start).format(DATE_FORMAT)) ||
      "",
    reservation_end:
      (reservationData &&
        moment(reservationData?.reservation_end).format(DATE_FORMAT)) ||
      "",
    forced_price: reservationData?.price || "",
    container_id: reservationData?.container_id || undefined,
  };

  const onSubmit = async (body) => {
    await panelSave({ body: { ...body, is_accepted: 2 } });
    refetch();
    onClose();
  };

  return (
    <>
      <div className="modal fade show" onClick={onClose}>
        <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="ReservationFormModalLabel">
                Dane rezerwacji
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              <Form>
                <div className="modal-body row">
                  <div className="mb-3 col-6">
                    <label htmlFor="name" className="form-label">
                      Imię:
                    </label>
                    {reservationData ? (
                      <Field
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        disabled
                      />
                    ) : (
                      <Field
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                      />
                    )}
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mb-3 col-6">
                    <label htmlFor="lastName" className="form-label">
                      Nazwisko:
                    </label>
                    {reservationData ? (
                      <Field
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        disabled
                      />
                    ) : (
                      <Field
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                      />
                    )}
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  {!reservationData && (
                    <div className="mb-3 col-6">
                      <label htmlFor="phone" className="form-label">
                        Numer telefonu:
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  )}

                  {!reservationData && (
                    <div className="mb-3 col-6">
                      <label htmlFor="mail" className="form-label">
                        Email:
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="mail"
                        name="mail"
                      />
                      <ErrorMessage
                        name="mail"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  )}

                  <div className="mb-3 col-6">
                    <label htmlFor="reservation_start" className="form-label">
                      Data rozpoczęcia:
                    </label>
                    <Field
                      type="date"
                      className="form-control"
                      id="reservation_start"
                      name="reservation_start"
                    />
                    <ErrorMessage
                      name="reservation_start"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3 col-6">
                    <label htmlFor="reservation_end" className="form-label">
                      Data zakończenia:
                    </label>
                    <Field
                      type="date"
                      className="form-control"
                      id="reservation_end"
                      name="reservation_end"
                    />
                    <ErrorMessage
                      name="reservation_end"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3 col-6">
                    <label htmlFor="forced_price" className="form-label">
                      Cena:
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="forced_price"
                      name="forced_price"
                    />
                    <ErrorMessage
                      name="forced_price"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3 col-6">
                    <label htmlFor="container_id" className="form-label">
                      Id kontenera:
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      id="container_id"
                      name="container_id"
                    >
                      <option key={0} value={0}>
                        Wybierz
                      </option>
                      {payload?.data?.results.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="container_id"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Wyślij
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={onClose}
                  >
                    Zamknij
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationForm;
