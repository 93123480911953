import { useClients } from "../../../OdevFetch";
import { useState } from "react";
import Popup from "../Modules/Popup/Popup";
import ClientForm from "../Forms/ClientForm";

const Clients = () => {
  const { payload, loading, refetch, save } = useClients();
  const clients = payload ? payload.data.results : [];

  const [invoicePopupState, setInvoicePopupState] = useState({
    data: {},
    isOpen: false,
  });

  const [editClientPopup, setEditClientPopup] = useState({
    isOpen: false,
    data: {},
  });

  const [removeClientPopup, setRemoveClientPopup] = useState({
    isOpen: false,
    data: {},
  });

  if (loading) return <div>LOADING</div>;

  return (
    <div className="container-fluid">
      <h1 class="h3 mt-4 mb-3 text-gray-800">Lista klientów</h1>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <table
              className="table table-bordered"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>Id klienta</th>
                  <th>Imię</th>
                  <th>Nazwisko</th>
                  <th>Numer telefonu</th>
                  <th>Email</th>
                  <th>Notatki</th>
                  <th>Akcja</th>
                </tr>
              </thead>
              <tbody>
                {clients.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.lastName}</td>
                    <td>{item.phone}</td>
                    <td>{item.mail}</td>
                    <td>
                      <button
                        onClick={() =>
                          setInvoicePopupState({
                            isOpen: true,
                            data: item,
                          })
                        }
                        className="btn btn-primary"
                      >
                        Notatki
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-warning"
                        onClick={() =>
                          setEditClientPopup({
                            isOpen: true,
                            data: { ...item },
                          })
                        }
                      >
                        Edytuj
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() =>
                          setRemoveClientPopup({
                            isOpen: true,
                            data: { ...item },
                          })
                        }
                      >
                        Usuń
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button
        className="btn btn-primary"
        onClick={() => setEditClientPopup({isOpen: true})}
      >
        Dodaj klienta
      </button>
      {removeClientPopup.isOpen && (
        <ClientForm
          onClose={() => setRemoveClientPopup(false)}
          action="remove"
          clientData={removeClientPopup.data}
          refetch={refetch}
        />
      )}
      {editClientPopup.isOpen && (
        <ClientForm
          onClose={() => setEditClientPopup({ isOpen: false, data: null })}
          clientData={editClientPopup.data}
          action="edit"
          refetch={refetch}
        />
      )}
      {invoicePopupState.isOpen && (
        <Popup
          isOpen={true}
          onClose={() =>
            setInvoicePopupState({
              isOpen: false,
              data: null,
            })
          }
        >
          <h4>Notatki</h4>
          <textarea
            value={invoicePopupState.data.notes}
            onChange={(e) =>
              setInvoicePopupState({
                data: {
                  ...invoicePopupState.data,
                  notes: e.target.value,
                },
                isOpen: true,
              })
            }
          ></textarea>
          <button
            className="btn btn-success"
            onClick={async () => {
              await save({
                body: {
                  id: invoicePopupState.data.id,
                  notes: invoicePopupState.data.notes,
                },
              });
              setInvoicePopupState({
                isOpen: false,
                data: null,
              });

              refetch();
            }}
          >
            Zapisz
          </button>
        </Popup>
      )}
    </div>
  );
};

export default Clients;
