import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRoutes } from "react-router-dom";

import Waiting from "./SubPages/Waiting";
import Containers from "./SubPages/Containers";
import Cash from "./SubPages/Cash";
import Clients from "./SubPages/Clients";
import Reservations from "./SubPages/Reservations";

import "../../vendor/sb-admin-2.min.scss";
import "../../vendor/dataTables.scss";
import UserProvider from "./Providers/UserProvider";

import "./Facilities.scss";

const Facilities = () => {
  const navigate = useNavigate();
  if (sessionStorage["access-token"] === undefined) {
    navigate("/login");
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const [currPage, setCurrPage] = useState(1);
  const [id, setId] = useState(null);

  const clickHandler = (id, currPage) => {
    setId(id);
    setCurrPage(currPage);
  };

  const logOut = (e) => {
    e.preventDefault();
    sessionStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const routes = useRoutes([
    { path: "/waiting", element: <Waiting /> },
    { path: "/containers", element: <Containers /> },
    { path: "/clients", element: <Clients /> },
    { path: "/reservations", element: <Reservations /> },
    { path: "/cash", element: <Cash /> },
    { path: "/", element: <Waiting /> },
  ]);

  return (
    <UserProvider>
      <div className="dashboard">
        <div id="wrapper">
          <ul
            className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
            id="accordionSidebar"
          >
            <a
              className="sidebar-brand d-flex align-items-center justify-content-center"
              href="index.html"
            >
              <div className="sidebar-brand-icon rotate-n-15">
                <i className="fas fa-laugh-wink"></i>
              </div>
              <div className="sidebar-brand-text mx-3">
                Bezpieczny schowek 1.0
              </div>
            </a>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/panel/waiting");
                }}
              >
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Oczekujące na akceptację</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/panel/reservations");
                }}
              >
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Rezerwacje</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/panel/containers");
                }}
              >
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Kontenery</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/panel/clients");
                }}
              >
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Klienci</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/panel/cash");
                }}
              >
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Ceny</span>
              </a>
            </li>
          </ul>

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item dropdown no-arrow">
                    <a
                      class="nav-link dropdown-toggle"
                      onClick={logOut}
                      role="button"
                    >
                      <img
                        class="img-profile rounded-circle"
                        src="/images/logout.png"
                      />
                    </a>
                  </li>
                </ul>
              </nav>
              {routes}
            </div>
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

export default Facilities;
