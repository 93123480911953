import { fetchSetting } from "./fetchConfig";
import { pick } from "lodash";

export const returnFetch = async ({ endpoint, body }) => {
  const output = await fetch(`${process.env.REACT_APP_API_PATH}/${endpoint}`, {
    ...fetchSetting,
    headers: {
      ...fetchSetting.headers,
      ["authorization"]: `Bearer ${sessionStorage.getItem("access-token")}`,
    },
    body: body ? JSON.stringify(body) : "",
  })
    .then((response) => response.json())
    .then((res) => {
      if (
        res &&
        res.status &&
        res.message &&
        !res.message.includes("ERROR-LOGS: ")
      ) {
        document.showAlert(res.message, res.status);
      }

      return res;
    });

  const outputObject = pick(output, ["body", "status"]);

  return outputObject;
};
