const path = "images/svg/";
export const IMAGES = {
  COURT: `${path}plyta.svg`,
  TYPE_5: `${path}type_5_blue.svg`,
  TYPE_5_UNACTIVE: `${path}type_5_gray.svg`,
  TYPE_4: `${path}type_4_blue.svg`,
  TYPE_4_UNACTIVE: `${path}type_4_gray.svg`,
  TYPE_3: `${path}type_3_blue.svg`,
  TYPE_3_UNACTIVE: `${path}type_3_gray.svg`,
  TYPE_2_BACK: `${path}type_2_blue.svg`,
  TYPE_2_BACK_UNACTIVE: `${path}type_2_gray.svg`,
  TYPE_1_BACK: `${path}type_1_blue_back.svg`,
  TYPE_1_BACK_UNACTIVE: `${path}type_1_gray_back.svg`,
  TYPE_1_FRONT: `${path}type_1_blue_front.svg`,
  TYPE_1_FRONT_UNACTIVE: `${path}type_1_gray_front.svg`,
};

export const configContainers = [
  // 1M12
  {
    minValue: 1,
    maxValue: 1,
    positionValue: () => ({
      top: "87%",
      left: "40%",
      width: "9%",
      zIndex: "99999",
    }),
    image: IMAGES.TYPE_5,
    disableImage: IMAGES.TYPE_5_UNACTIVE,
    sizeType: "12",
  },
  // 1M50
  {
    minValue: 1,
    maxValue: 1,
    positionValue: () => ({
      top: "81.7%",
      left: "34.8%",
      width: "11.7%",
      zIndex: "9999",
    }),
    image: IMAGES.TYPE_4,
    disableImage: IMAGES.TYPE_4_UNACTIVE,
    sizeType: "50",
  },
  // left side
  {
    minValue: 8,
    maxValue: 18,
    positionValue: (id) => ({
      top: `calc(80.2% - ${id * 2.4 - 1}%)`,
      left: `calc(31.5% - ${id * 2.6 - 0.6}%)`,
      zIndex: 100 - id,
      width: "6.5%",
    }),
    image: IMAGES.TYPE_1_BACK,
    disableImage: IMAGES.TYPE_1_BACK_UNACTIVE,
    sizeType: "6",
  },
  // topSide left
  {
    minValue: 1,
    maxValue: 20,
    positionValue: (id) => ({
      top: `calc(46.8% - ${id * 1.35 - 1}%)`,
      left: `calc(11.5% + ${id * 1.35}%)`,
      zIndex: 50 - id,
      width: "3%",
    }),
    image: IMAGES.TYPE_2_BACK,
    disableImage: IMAGES.TYPE_2_BACK_UNACTIVE,
    sizeType: "2",
  },
  // topSide right
  {
    minValue: 21,
    maxValue: 27,
    positionValue: (id) => ({
      top: `calc(18.7% - ${id * 2.3 - 1}%)`,
      left: `calc(39.2% + ${id * 2.3}%)`,
      zIndex: 30 - id,
      width: "4%",
    }),
    image: IMAGES.TYPE_3,
    disableImage: IMAGES.TYPE_3_UNACTIVE,
    sizeType: "2",
  },
  // right side
  {
    minValue: 1,
    maxValue: 7,
    positionValue: (id) => ({
      top: `calc(25% - ${id * 2.4}%)`,
      left: `calc(82% + ${id * -2.7}%)`,
      zIndex: 90 - id,
      width: "6.5%",
    }),
    image: IMAGES.TYPE_1_FRONT,
    disableImage: IMAGES.TYPE_1_FRONT_UNACTIVE,
    sizeType: "6",
  },
  {
    minValue: 19,
    maxValue: 19,
    positionValue: (id) => ({
      top: "27.4%",
      left: "84.7%",
      zIndex: 90 - id,
      width: "6.5%",
    }),
    image: IMAGES.TYPE_1_FRONT,
    disableImage: IMAGES.TYPE_1_FRONT_UNACTIVE,
    sizeType: "6",
  },
];
