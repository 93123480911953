import { useReservations } from "../../../OdevFetch/hooks/useReservations";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants";
import { useState } from "react";
import Popup from "../Modules/Popup/Popup";
import ReservationForm from "../Forms/ReservationForm";

const Reservations = () => {
  const { payload, loading, refetch, save, remove } = useReservations({
    query: { is_accepted: 2 },
  });
  const [invoicePopupState, setInvoicePopupState] = useState({
    data: {},
    isOpen: false,
  });
  const [reservationPopupState, setReservationPopupState] = useState({
    data: {},
    isOpen: false,
  });
  const [removeReservationPopup, setRemoveReservationPopup] = useState({
    isOpen: false,
    data: {},
  });

  const closeReservationPopup = () => {
    setReservationPopupState({
      data: {},
      isOpen: false,
    });
  };

  const closeRemoveReservationPopup = () => {
    setRemoveReservationPopup({
      data: {},
      isOpen: false,
    });

    refetch();
  };

  const reservations = payload ? payload.data.results : "";

  if (loading) return <div>LOADING</div>;

  const getFinishedPrice = (price) => {
    const floatPrice = parseFloat(price);
    const bruttoPrice = (floatPrice * 1.23).toFixed(2);
    return (
      <>
        {floatPrice.toFixed(2)} zł <br /> {bruttoPrice} zł
      </>
    );
  };

  console.log(removeReservationPopup);
  return (
    <div className="container-fluid">
      {reservationPopupState.isOpen && (
        <ReservationForm
          onClose={closeReservationPopup}
          reservationData={reservationPopupState.data}
          refetch={refetch}
        />
      )}
      {removeReservationPopup.isOpen && (
        <div className="modal fade show" onClick={closeRemoveReservationPopup}>
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5>Czy na pewno chcesz usunąć rezerwację?</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeRemoveReservationPopup}
                ></button>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={async () => {
                    await remove(removeReservationPopup.data);
                    closeRemoveReservationPopup();
                  }}
                >
                  Usuń
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={closeRemoveReservationPopup}
                >
                  Zamknij
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <h1 class="h3 mt-4 mb-3 text-gray-800">Lista rezerwacji</h1>
      <button
        className="btn btn-primary"
        onClick={() =>
          setReservationPopupState({
            isOpen: true,
            id: null,
          })
        }
      >
        Dodaj rezerwację
      </button>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <table
              className="table table-bordered"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>Id kontenera</th>
                  <th>Imię i nazwisko</th>
                  <th>Telefon</th>
                  <th>Start rezerwacji</th>
                  <th>Koniec rezerwacji</th>
                  <th>Kwota</th>
                  <th>Dane do faktury</th>
                  <th>Akcja</th>
                </tr>
              </thead>
              <tbody>
                {reservations.map((item) => (
                  <tr>
                    <td>{item.container.description}</td>
                    <td>
                      {item?.client?.name} {item?.client?.lastName}
                    </td>

                    <td>{item?.client?.phone || "-"}</td>
                    <td>
                      {(item?.reservation_start &&
                        moment(item.reservation_start).format(DATE_FORMAT)) ||
                        "-"}
                    </td>
                    <td>
                      {(item?.reservation_end &&
                        moment(item.reservation_end).format(DATE_FORMAT)) ||
                        "-"}
                    </td>
                    <td>
                      {getFinishedPrice(item?.forced_price || item?.price)}
                    </td>
                    <td>
                      {item.invoice_id && (
                        <button
                          onClick={() =>
                            setInvoicePopupState({
                              isOpen: true,
                              data: item.invoice,
                            })
                          }
                          className="btn btn-primary"
                        >
                          Dane do faktury
                        </button>
                      )}
                    </td>
                    <td>
                      {" "}
                      <button
                        className="btn btn-warning"
                        onClick={() =>
                          setReservationPopupState({
                            isOpen: true,
                            data: item,
                          })
                        }
                      >
                        Edytuj
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() =>
                          setRemoveReservationPopup({
                            isOpen: true,
                            data: item,
                          })
                        }
                      >
                        Usuń
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {invoicePopupState.isOpen && (
        <Popup
          isOpen={true}
          onClose={() =>
            setInvoicePopupState({
              isOpen: false,
              data: null,
            })
          }
        >
          <h4>Dane fakturowe</h4>
          <ul>
            {[
              { name: "nip", label: "Nip" },
              { name: "company", label: "Nazwa Firmy" },
              { name: "name", label: "Imię" },
              { name: "lastName", label: "Nazwisko" },
              { name: "street", label: "Ulica" },
              { name: "buildingNumber", label: "Numer budynku" },
              { name: "localNumber", label: "Numer lokalu" },
              { name: "zipCode", label: "Kod pocztowy" },
            ].map((item) => (
              <li>
                <b>{item.label}: </b>
                <span>{invoicePopupState.data[item.name]}</span>
              </li>
            ))}
          </ul>
        </Popup>
      )}
    </div>
  );
};

export default Reservations;
