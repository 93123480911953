import "./style.scss";
import { IMAGES, configContainers } from "./config";

const RegisterWidget = ({ containers, setSelectedContainer }) => {
  console.log({ containers });
  const generateAreaContainers = (config) => {
    const { minValue, maxValue, positionValue, image, disableImage, sizeType } =
      config;
    const preparedContainers = [];
    let positionCounter = 0;
    for (var currentId = minValue; currentId <= maxValue; currentId++) {
      const containerData = containers.find(
        (containerConfig) => {
          console.log(containerConfig.description, `${sizeType}M${String(currentId).padStart(2, "0")}`)
          return containerConfig.description ==
          `${sizeType}M${String(currentId).padStart(2, "0")}`
        }
      );
      preparedContainers.push(
        <img
          src={!containerData.reserved ? image : disableImage}
          className={"container-box" + ` ${currentId}`}
          style={positionValue(positionCounter)}
          onClick={() =>
            !containerData.reserved ? setSelectedContainer(containerData) : null
          }
        />
      );
      positionCounter++;
    }
    return preparedContainers;
  };

  return (
    <div className="register-widget-module">
      <img className="court" src={IMAGES.COURT} />
      {configContainers.map((config) => generateAreaContainers(config))}
    </div>
  );
};

export default RegisterWidget;
