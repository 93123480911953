import { IMAGES } from "../RegisterWidget/config";
import styles from "./ContainerTileTable.module.scss";

const ContainerTileTable = ({ data, setSelectedContainer }) => {
  const getData = (container) => {
    switch (container.type) {
      case "1":
        return {
          images: IMAGES.TYPE_1_FRONT,
          width: "2,2m",
          depth: "6m",
        };
      case "2":
        return {
          images: IMAGES.TYPE_1_FRONT,
          width: "1,1m",
          depth: "2,2m",
        };
      case "3":
        return {
          images: IMAGES.TYPE_3,
          width: "1,8m",
          depth: "2,2m",
        };
      case "4":
        return {
          images: IMAGES.TYPE_4,
          width: "4m",
          depth: "12m",
        };
      case "5":
        return {
          images: IMAGES.TYPE_5,
          width: "12m",
          depth: "2,2",
        };
      default:
        return container.type;
    }
  };

  if (!data.reserved) {
    const preparedData = getData(data);
    return (
      <div className={styles.root} onClick={setSelectedContainer}>
        <div className={styles.root_img}>
          <img src={preparedData.images} alt="container svg"></img>
        </div>
        <div className={styles.root_content}>
          <h6>Kontener nr {data.description}</h6>
          <p>Szerokość: {preparedData.width}</p>
          <p>Głębokość: {preparedData.depth}</p>
        </div>
      </div>
    );
  }
};

export default ContainerTileTable;
