import MainPage from "./pages/MainPage/MainPage";
import "./styles/global.scss";
import "./styles/settings.scss";
import Login from "./modules/Login/Login";
import Facilities from "./pages/Facilities/Facilities";
import { useRoutes } from "react-router-dom";

function App() {
  const routes = useRoutes([
    {
      path: "/",
      element: <MainPage />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/panel/*",
      element: <Facilities />,
    },
  ]);

  return <>{routes}</>;
}

export default App;
