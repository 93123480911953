import styles from "./ContainerSingle.module.scss";
import { useState } from "react";

import ContainerPresentation from "./templates/ContainerPresentation";
import InvoicesForm from "./templates/InvoicesForm";
import Confirmation from "./templates/Confirmation";
import { useReservations } from "../../OdevFetch/hooks/useReservations";
import { Formik, Form, Field, ErrorMessage } from "formik";

const validateMail = (value) => {
  let error;
  if (!value) {
    error = "Pole email jest wymagane";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    error = "Nieprawidłowy adres email";
  }
  return error;
};

const validateText = (value) => {
  let error;
  if (!value) {
    error = "To pole jest wymagane";
  }
  return error;
};

const validatePhone = (value) => {
  let error;
  if (!value) {
    error = "Pole numer telefonu jest wymagane";
  } else if (!/^[0-9]{9}$/i.test(value)) {
    error = "Nieprawidłowy numer telefonu";
  }
  return error;
};

const ContainerSingle = ({ onBack, fromDate, duration, containerData }) => {
  const [invoiceData, setInvoiceData] = useState(null);
  const PAGE = {
    FORM: "form",
    CONFIRMATION: "confirmation",
  };
  const [page, setPage] = useState(PAGE.FORM);
  const { save } = useReservations({ isLazy: true });
  // FORM

  const initialValues = {
    name: "",
    lastName: "",
    phone: "",
    mail: "",
  };

  const [isInvoicesFormVisible, setIsInvoicesFormVisible] = useState(false);

  const onReservation = (value) => {
    setPage(PAGE.CONFIRMATION);
    save({
      body: {
        invoice: invoiceData,
        ...value,
        reservation_start: fromDate,
        duration,
        container_id: containerData.container_number,
      },
    });
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.root_container}>
          {page === PAGE.FORM && (
            <>
              <ContainerPresentation params={containerData} />

              <div className={styles.root_container_containerReservation}>
                <Formik initialValues={initialValues} onSubmit={onReservation}>
                  <Form>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Imię"
                      validate={validateText}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />

                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Nazwisko"
                      validate={validateText}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error-message"
                    />

                    <Field
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Numer telefonu"
                      validate={validatePhone}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error-message"
                    />

                    <Field
                      type="email"
                      id="mail"
                      name="mail"
                      placeholder="E-mail"
                      validate={validateMail}
                    />
                    <ErrorMessage
                      name="mail"
                      component="div"
                      className="error-message"
                    />

                    <label>Data rozpoczęcia:</label>
                    <input type="date" value={fromDate} disabled />

                    <label>Długość rezerwacji:</label>
                    <input
                      disabled={true}
                      defaultValue={duration + " miesięcy"}
                    />

                    <InvoicesForm
                      isVisible={isInvoicesFormVisible}
                      onClose={() => setIsInvoicesFormVisible(false)}
                      onSave={(data) => setInvoiceData(data)}
                      invoiceData={invoiceData}
                    />
                    <button
                      className="btn"
                      type="button"
                      onClick={() => setIsInvoicesFormVisible(true)}
                    >
                      Faktura
                    </button>
                    <button type="button" className="btn" onClick={onBack}>
                      Wróć
                    </button>
                    <button className="btn" type="submit">
                      Zarezerwuj
                    </button>
                  </Form>
                </Formik>
              </div>
            </>
          )}
          {page === PAGE.CONFIRMATION && (
            <Confirmation containerData={containerData} />
          )}
        </div>
      </div>
    </>
  );
};

export default ContainerSingle;
